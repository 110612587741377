<template>
  <div class="login-component">
    <section v-loading="loading" element-loading-text="Gathering Data...">
      <el-row :gutter="12">
        <el-col :span="21" style="padding: 10px">
          <b style="padding-left: 23px">{{ componentData.component_name }}</b>
        </el-col>
        <el-col :span="1">
          <el-dropdown
            trigger="click"
            v-if="!hide_options && isExpandTable === false"
          >
            <el-button
              icon="el-icon-more"
              type="text"
              class="icon-style mr-1"
            ></el-button>

            <el-dropdown-menu slot="dropdown">
              <a @click="editDialogBox">
                <el-dropdown-item>Edit</el-dropdown-item>
              </a>
              <a @click="deleteComponent">
                <el-dropdown-item>Delete</el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown
            trigger="click"
            v-if="!hide_options && isExpandTable === true"
          >
            <el-button icon="el-icon-more" type="text"></el-button>

            <el-dropdown-menu slot="dropdown">
              <a @click="editDialogBox">
                <el-dropdown-item>Edit</el-dropdown-item>
              </a>
              <a @click="deleteComponent">
                <el-dropdown-item>Delete</el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="1" class="icon-style">
          <el-button
            class="fullscreen-button"
            icon="el-icon-full-screen"
            type="text"
            v-if="isExpandTable === false"
            @click="expendTable"
          ></el-button>
          <el-button
            v-if="isExpandTable === true"
            class="fullscreen-button"
            icon="el-icon-full-screen"
            type="text"
            @click="expendTable"
          ></el-button>
        </el-col>
      </el-row>
      <hr style="margin-top: unset; margin-bottom: unset" />
      <div class="content-container">
        <el-row :gutter="12" class="center-row">
          <el-col :span="20">
            <p class="DateStyles">{{ todayDate }}</p>
            <p class="DayStyles">{{ getDayOfWeek(currentDay) }}</p>
          </el-col>
          <el-col :span="2">
            <img
              src="@/assets/img/calculator/Early_Morning_Icon.svg"
              alt="Early_Morning_Icon"
            />
          </el-col>
        </el-row>
        <el-row>
          <div v-if="defaultTimeHours == 12">
            <p class="timeStyles">
              {{ hours === 0 ? 0 : hours > 12 ? hours - 12 : hours }} :
              {{ minutes }} : {{ seconds }} {{ hours >= 12 ? "PM" : "AM" }}
            </p>
          </div>
          <div v-else>
            <p class="timeStyles">
              {{ hours }} : {{ minutes }} : {{ seconds }} Hrs
            </p>
          </div>
        </el-row>
        <el-row class="section-wrapper">
          <span @click="openSwipes" class="color">View Swipes</span>
          <div class="bottom-right-content" v-if="shouldShowButtons">
            <el-button
              v-if="currentSwipeState"
              v-loading="loading"
              class="button"
              type="primary"
              size="mini"
              style="
                background-color: #409eff;
                color: #ffffff;
                margin-left: 20px;
                border-radius: 20px;
              "
              @click="checklogin"
              :disabled="isButtonDisabled"
            >
              <div class="text-wrpper">
                <img
                  src="@/assets/img/calculator/Sign_In_Icon.svg"
                  alt="Sign_In_Icon"
                  class="sign_img"
                />
                <span class="button-text">{{ component.start_button }}</span>
              </div>
            </el-button>
            <el-button
              v-else
              @click="checklogout"
              v-loading="loading"
              class="button"
              type="danger"
              size="mini"
              style="
                background-color: #409eff;
                color: #ffffff;
                border-radius: 20px;
              "
              :disabled="isButtonDisabled"
            >
              <div class="text-wrpper">
                <img
                  src="@/assets/img/calculator/Sign_In_Icon.svg"
                  alt="Sign_In_Icon"
                  class="sign_img"
                />
                <span class="button-text">{{ component.end_button }}</span>
              </div>
            </el-button>
          </div>
        </el-row>
      </div>
    </section>

    <div>
      <dialog-component
        :visible="selectPopUp"
        :title="'select Location'"
        :containerWidth="getIsMobile ? '100%' : '30%'"
        :destroy-on-close="true"
        :containerMinHeight="'25%'"
        @before-close="selectPopUp = false"
        center
        class="edit-options-popup"
      ><div v-if="this.GeoLoginLocation.input_type == 'LOCATION'">
        <location-execute
                    :data="GeoLoginLocation"
                    v-if="GeoLoginLocation.input_type === 'LOCATION'"
                    :form="LoginLocation"
                  ></location-execute>
      </div>
        <p v-if="loginLocationFieldParts"  class="label">Select</p>
        <el-select v-if="loginLocationFieldParts" v-model="DropDownOption">
          <el-option
            v-for="(option, index) in getCurrentFieldOptions(
              loginLocationFieldParts.split('#')[0] || '',
              loginLocationFieldParts.split('#')[1] || '',
              Currententity
            )"
            :label="option"
            :value="option"
            :key="index"
          >
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="selectPopUp = false">Cancel</el-button>
          <el-button type="primary" @click="login">Confirm</el-button>
        </span>
      </dialog-component>
      <dialog-component
        :visible="selectlogoutPopUp"
        :title="'select Location'"
        :containerWidth="getIsMobile ? '100%' : '30%'"
        :destroy-on-close="true"
        :containerMinHeight="'25%'"
        @before-close="selectlogoutPopUp = false"
        center
        class="edit-options-popup"
      >
      <div v-if="this.GeoLoginLocation.input_type == 'LOCATION'">
       
        <location-execute
          :data="GeoLoginLocation"
          v-if="GeoLoginLocation.input_type === 'LOCATION'"
          :form="LoginLocation"
          ></location-execute>
      </div>
        <p v-if="logoutLocationFieldParts"  class="label">Select</p>
        <el-select v-if="logoutLocationFieldParts" v-model="DropDownOption2">
          <el-option
            v-for="(option, index) in getCurrentFieldOptions(
              logoutLocationFieldParts.split('#')[0] || '',
              logoutLocationFieldParts.split('#')[1] || '',
              Currententity
            )"
            :label="option"
            :value="option"
            :key="index"
          >
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="selectlogoutPopUp = false">Cancel</el-button>
          <el-button type="primary" @click="logout">Confirm</el-button>
        </span>
      </dialog-component>
      <dialog-component
        :visible="optionsDialogVisible"
        :title="'View Swipes'"
        :containerWidth="getIsMobile ? '100%' : '30%'"
        :destroy-on-close="true"
        :containerMinHeight="'25%'"
        @before-close="optionsDialogVisibleClose"
        :isShowFooter="false"
        center
        class="edit-options-popup"
      >
        <el-table
          border
          :data="data1"
          :header-row-class-name="'my-header-row'"
          class="mt-3 mb-3"
          :show-header="true"
        >
          <el-table-column class="labelSty" label="In">
            <template slot-scope="scope">{{
              scope.row.time1 | globalTimeFormat
            }}</template>
          </el-table-column>
          <el-table-column class="labelSty" label="out">
            <template slot-scope="scope">{{
              scope.row.time2 | globalTimeFormat
            }}</template>
          </el-table-column>
          <el-table-column class="labelSty" label="Duration">
            <template slot-scope="scope">{{ scope.row.duration }}</template>
          </el-table-column>
        </el-table>
      </dialog-component>
      <dialog-component
        title="Edit Component"
        :visible.sync="centerLoginDialogVisible"
        :width="getIsMobile ? '80%' : '60%'"
        :containerWidth="'50%'"
        :containerMinHeight="'20%'"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @before-close="centerLoginDialogVisible = false"
      >
        <div v-if="addStatsData" class="main-form">
          <label class="label">Component Name</label>
          <el-input
            v-model="addStatsData.component_name"
            placeholder="Enter component name"
            class="mb-1"
          ></el-input>
          <label class="label">Start Time</label>
          <el-input
            v-model="addStatsData.start_button"
            placeholder="Enter component name"
            class="mb-1"
          ></el-input>
          <label class="label">End Time</label>
          <el-input
            v-model="addStatsData.end_button"
            placeholder="Enter component name"
            class="mb-1"
          ></el-input>
          <el-table :data="addStatsData.loginsDataDetails" border>
            <el-table-column label="Component Info">
              <template slot-scope="scope">
                {{ scope.row.component }}
              </template>
            </el-table-column>
            <el-table-column prop="Contact Type" label="Fields" width="200">
              <template slot-scope="scope">
                <el-select v-model="scope.row.field" size="mini" clearable>
                  <el-option
                    v-for="(field, i) of allDateTimeFields"
                    :key="field + i"
                    :value="field.template_key"
                    :label="field.label"
                    :disabled="isFieldDisabled(field)"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
          <el-row>
              <!-- <el-checkbox   class="mb-1 mt-1 " v-model="addStatsData.isDropdownVisible">Add Geo Location Fields</el-checkbox> -->
              <el-checkbox v-model="addStatsData.isGeoFenceRequired"
            >Setup Geofence</el-checkbox
          >
    <!-- Two Dropdowns (Visible when Checkbox is Checked) -->
    <el-row v-if="addStatsData.isDropdownVisible" :gutter="20">
      <el-col :span="12">
        <div style="display: flex; align-items: center">
        <label class="label">Login Location</label>
      </div>
          <el-select v-model="addStatsData.selectedLoginLocation" placeholder="Select Login Location">
            <el-option 
              v-for="(field, i) in allLocationFields"
              :key="field + i"
              :value="field.template_key"
              :label="field.label"
              :disabled="field.template_key === selectedLogoutLocation"  
            ></el-option>
          </el-select>
      </el-col>

      <el-col :span="12" >
        <div  style="display: flex; align-items: center">
        <label class="label">Logout Location</label>
        </div>
          <el-select v-model="addStatsData.selectedLogoutLocation" placeholder="Select Logout Location">
            <el-option 
              v-for="(field, i) in allLocationFields"
              :key="field + i"
              :value="field.template_key"
              :label="field.label"
              :disabled="field.template_key === selectedLoginLocation"  
            ></el-option>
          </el-select>
      </el-col>
    </el-row>
    <el-row v-if="addStatsData.isGeoFenceRequired" :gutter="20"   class="mb-1 mt-1 ">
      <el-col :span="12">
        <label class="label">Enter Fence Radius</label>
        <br/>
          <el-input-number
            v-model="addStatsData.geoFenceRadius"
            :min="1"
            :max="50000"
          ></el-input-number>
        </el-col>
        <el-col :span="8">
          <label class="label">Enter Fence Center Address (or) co-ordinates</label>
          <el-input
            v-model="addStatsData.geoFenceCoordinates"
            placeholder="Enter Address"
            @change="getEpicenter"
          ></el-input>
        </el-col>
    </el-row>
            </el-row>
          <el-row>
            <el-col :span="12">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
                v-if="isEntityDashboard"
              >
                <div style="display: flex; align-items: center">
                  <label>Select User Field</label>
                </div>
                <el-select
                  v-model="addStatsData.user_field"
                  clearable
                  style="width: 95%"
                >
                  <el-option
                    v-for="(field, i) of entityField"
                    :key="field + i"
                    :value="field.template_key"
                    :label="field.label"
                  >
                  </el-option>
                </el-select>
              </div>
              <div v-if="isEntityDashboard">
                <label>Filter</label>
                <br />
                <el-select
                  placeholder="Select filter"
                  clearable
                  v-model="addStatsData.loginFilter"
                  class="mb-1"
                  style="width: 95%"
                >
                  <el-option
                    v-for="fl in (loginEntityFilters || []).filter(
                      (e) => !e.parent_filter_id
                    )"
                    :key="fl._id"
                    :value="fl._id"
                    :label="fl.filter_name"
                  ></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label class="label">Select Field</label>
              <el-select
                v-model="componentData.companyuser_field"
                clearable
                style="width: 95%"
              >
                <el-option
                  v-for="(field, i) of textField"
                  :key="field + i"
                  :value="field.template_key"
                  :label="field.label"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="12">
              <div v-if="shouldDisplayInput">
                <div style="display: flex; align-items: center">
                  <label class="label">No. of days</label>
                </div>
                <el-input
                  v-model="addStatsData.Days"
                  type="number"
                  placeholder="Enter number of days"
                  class="mb-1"
                  style="width: 95%"
                ></el-input>
              </div>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="centerLoginDialogVisible = false"
            >Cancel</el-button
          >
          <el-button type="primary" @click="saveEditData">Confirm</el-button>
        </span>
      </dialog-component>
    </div>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import EntitiesHelper from "@/mixins/EntitiesHelper";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper";
import FieldsFormatHelper from "../../mixins/FieldsFormatHelper";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import { fetchAllEntityFiltersByEntity } from "@/repo/filtersRepo";
import { postAPICall } from "@/helpers/httpHelper";
import { fetchEntityById } from "@/repo/entityRepo";
import { fetchGlobalVariables } from "@/repo/globalVariables";
import axios from "axios";
export default {
  mixins: [
    userPermissionsHelper,
    EntitiesHelper,
    TemplateBuilderHelper,
    FieldsFormatHelper,
  ],
  components: {
    LocationExecute: () =>
      import("../templates/formComponentsExecute/LocationExecute.vue"),
  },

  props: {
    component: Object,
    index: Number,
    hide_options: Boolean,
    isFromApplicationuser: Boolean,
    customDashboard: Object,
    data: Object,
    entityDataId: String,
    getAllEntities: Object,
  },

  computed: {
    loginLocationFieldParts() {
      if (this.loginLocation || this.loginLocation.field) {
        return this.loginLocation.field;
      }
      return ["", ""];
    },
    logoutLocationFieldParts() {
      if (this.logoutLocation || this.logoutLocation.field) {
        return this.logoutLocation.field;
      }
      return ["", ""];
    },
    getIsExpandTable() {
      return this.isExpandTable;
    },
    ...mapGetters("entities", [
      "getAllCalendarsData",
      // "getAllEntities",
      "getEntityRecordsForTable",
      "getEntitiesDatasByQuery",
    ]),
    ...mapGetters("globalVariables", [
      "getGlobalVariableById",
      "getAllGlobalVariabless",
    ]),
    ...mapGetters("auth", [
      "getDefaultDateFormat",
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getActiveContactType",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("templatesData", [
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityData",
      "getUserTemplateDataUpdateStatus",
    ]),
    ...mapGetters("companyTemplates", ["getSingleCompanyTemplate"]),
    currentSwipeState() {
      if (!this.tableFields || !this.tableFields.length) {
        return false;
      }
      if (this.getEntitiesDatasByQuery?.length) {
        let toggleButton = [];
        let logInField = this.componentData?.loginsDataDetails.find(
          (fd) => fd.component == "Login"
        );
        let logOutField = this.componentData?.loginsDataDetails.find(
          (fd) => fd.component == "Logout"
        );
        let durationField = this.componentData?.loginsDataDetails.find(
          (fd) => fd.component == "Duration"
        );
        if (this.componentData.Days >= 1) {
          let tableData = this.tableFields.filter((e) => e.data_table_key);
          let tableName = tableData[0].data_table_key;
          this.getEntitiesDatasByQuery.map((eData) => {
            let rowData =
              eData.entityData[logInField.field.split("#")[0]]?.[tableName];
            (rowData || []).map((row) => {
              toggleButton.push({
                time1: row[logInField.field.split("#")[1]],
                time2: row[logOutField.field.split("#")[1]],
                duration: row[durationField.field.split("#")[1]],
              });
            });
          });
          if (toggleButton[toggleButton.length - 1]?.time2 == undefined) {
            return false;
          } else {
            return true;
          }
        } else {
          this.getEntitiesDatasByQuery.map((eData) => {
            toggleButton.push({
              time1:
                eData.entityData[logInField.field.split("#")[0]]?.[
                  logInField.field.split("#")[1]
                ],
              time2:
                eData.entityData[logOutField.field.split("#")[0]]?.[
                  logOutField.field.split("#")[1]
                ],
              duration:
                eData.entityData[durationField.field.split("#")[0]]?.[
                  durationField.field.split("#")[1]
                ],
            });
          });
          if (toggleButton[toggleButton.length - 1]?.time2 == undefined) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    },
    getAllEntitiesData() {
      return this.getAllEntities && this.getAllEntities.data
        ? this.getAllEntities.data
        : [];
    },
    isDashboardRoute() {
      return this.$route.path.includes("/custom/dashboard/");
    },
    isButtonDisabled() {
    if (this.componentData?.isGeoFenceRequired) {
      return this.isDashboardRoute || !this.ingeoFence;
    }
    return this.isDashboardRoute;
  },
    shouldShowButtons() {
      if (
        this.isApplicationUserDashboard &&
        this.componentData?.loginFilter != ""
      ) {
        return this.apiSuccess;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      textField: [],
      isList: false,
      showLabel: true,
      isDefalutPosq: true,
      loading: false,
      isLoggedIn: false,
      currentTime: this.getCurrentTime(),
      recording: false,
      recordedTimes: [],
      data1: [],
      data2: [],
      optionsDialogVisible: false,
      todayDate: "",
      isExpandTable: false,
      componentData: {},
      addStatsData: null,
      currentDay: new Date(),
      hours: "",
      minutes: "",
      seconds: "",
      centerLoginDialogVisible: false,
      info: [
        { component: "Login", field: "" },
        { component: "Logout", field: "" },
        { component: "Duration", field: "" },
        { component: "Date", field: "" },
      ],
      allDateTimeFields: [],
      permittedEntities: [],
      entity_data_id: "",
      selectedApplicationUser: "",
      isEntityDashboard: false,
      isApplicationUserDashboard: false,
      currentActiveWorkspace: null,
      swipes: {},
      tableKeyIndex: "",
      shouldDisplayInput: false,
      tableFields: [],
      selectedFieldType: "",
      dateTimeFieldObject: null,
      fieldSelected: false,
      selectedFirstField: null,
      EntityFields: [],
      entityField: [],
      userEntityField: {},
      loginEntityFilters: [],
      apiSuccess: false,
      selectPopUp: false,
      selectlogoutPopUp: false,
      DropDownOption: "",
      DropDownOption2: "",
      LoginLocation:{},
      LogoutLocation:"",
      Currententity: {},
      loginLocation: {},
      logoutLocation: {},
      GeoLoginLocation:{},
      GeoLogoutLocation:{},
      globalVariablesData: null,
      allLocationFields : [],
      ingeoFence:false,
      userCoordinates:[],
    };
  },
  async mounted() {
    this.componentData = JSON.parse(JSON.stringify(this.component));
    this.tableFields = await this.fetchEntityDetails(
      this.componentData.entity_id,
      true,
      false,
      true
    );
    this.Currententity = await fetchEntityById(this.componentData.entity_id);
    if (this.componentData.loginsDataDetails.length > 4) {
     
      this.loginLocation = this.componentData.loginsDataDetails.find(
        (fd) => fd.component == "Login Location"
      );
      this.logoutLocation = this.componentData.loginsDataDetails.find(
        (fd) => fd.component == "Logout Location"
      );
    }
    let activeWorkspace = this.getActiveContactType?.contact_type?._id;
    this.currentActiveWorkspace = (
      this.getAuthenticatedUser.contact_types || []
    ).find((e) => {
      let id =
        e.contact_type && e.contact_type._id
          ? e.contact_type._id
          : e.contact_type;
      if (id && id == activeWorkspace) {
        return true;
      }
    });
    if (
      this.getAuthenticatedUser &&
      this.getAuthenticatedUser.is_contact &&
      this.getAuthenticatedUser.is_contact == true
    ) {
      this.selectedApplicationUser =
        this.currentActiveWorkspace &&
        this.currentActiveWorkspace.account_data_id
          ? this.currentActiveWorkspace.account_data_id
          : "";
    } else {
      this.selectedApplicationUser = "ALL";
    }
    if (this.$route.name == "entity-custom-dashboard-config") {
      this.isEntityDashboard = true;
    }
    if (this.$route.name === "application-user-dashboard") {
      this.isApplicationUserDashboard = true;
    }
    const contactTypeId = this.getActiveContactType?.contact_type?._id;
    const role_id = this.getAuthenticatedUser?.activeRole?.role_id;
    let params = {
      entity_id: this.componentData.entity_id,
      userId: this.getAuthenticatedUser._id,
      // date: new Date(),
    };
    if (contactTypeId) {
      params.workSpace = contactTypeId;
    } else if (role_id) {
      params.role = role_id;
    }
    const globalVariables = await fetchGlobalVariables({
      get_all: true,
    });

    if (globalVariables?.data?.length) {
      this.globalVariablesData = [...globalVariables.data];
    }
    await this.$store.dispatch("entities/fetchEntitiesDataByQuery", params);
    this.loading = false;
    this.getDateTime();
    this.intervalId = setInterval(this.updateClock, 1000);
    this.updateTime();
    setInterval(this.updateTime, 1000);
    if (this.getEntitiesDatasByQuery?.length) {
      this.data2 = [];

      let logInField = this.componentData.loginsDataDetails.find(
        (fd) => fd.component == "Login"
      );
      let logOutField = this.componentData.loginsDataDetails.find(
        (fd) => fd.component == "Logout"
      );
      let durationField = this.componentData.loginsDataDetails.find(
        (fd) => fd.component == "Duration"
      );
      let dateField = this.componentData.loginsDataDetails.find(
        (fd) => fd.component == "Date"
      );
      if (this.componentData.Days) {
        let tableData = this.tableFields.filter((e) => e.data_table_key);
        let tableName = tableData[0].data_table_key;
        this.getEntitiesDatasByQuery.map((eData) => {
          let rowData =
            eData.entityData[logInField.field.split("#")[0]][tableName];
          rowData.map((row) => {
            this.data2.push({
              time1: row[logInField.field.split("#")[1]],
              time2: row[logOutField.field.split("#")[1]],
              duration: row[durationField.field.split("#")[1]],
              date: row[dateField.field.split("#")[1]],
            });
          });
        });
      } else {
        this.getEntitiesDatasByQuery.map((eData) => {
          this.data2.push({
            time1:
              eData.entityData[logInField.field.split("#")[0]][
                logInField.field.split("#")[1]
              ],
            time2:
              eData.entityData[logOutField.field.split("#")[0]][
                logOutField.field.split("#")[1]
              ],
            duration:
              eData.entityData[durationField.field.split("#")[0]][
                durationField.field.split("#")[1]
              ],
            date: eData.entityData[dateField.field.split("#")[0]][
              dateField.field.split("#")[1]
            ],
          });
        });
      }
    }
    if(this.componentData.isGeoFenceRequired){
    this.getUserLocation()
    }
      this.EntityFields = await this.fetchEntityDetails(
        this.componentData.entity_id,
        true,
        false,
        true
      );
      if(this.componentData.isGeoFenceRequired){
        this.ingeoFence = await this.isInsideGeoFence()
      }
    if (this.componentData.user_field) { 
      this.userEntityField = this.componentData.user_field.split("#")[1];
    }
      if( this.componentData.isDropdownVisible && this.componentData.selectedLoginLocation && this.componentData.selectedLogoutLocation){
      this.GeoLoginLocation =  this.EntityFields.find((e) => {
          return e.key === this.componentData.selectedLoginLocation.split("#")[1];
        });
        this.GeoLogoutLocation = this.EntityFields.find((e) => {
          return e.key ===  this.componentData.selectedLogoutLocation.split("#")[1];
        });
        this.LoginLocation = this.GeoLogoutLocation.key;
    }
    this.entityField = this.EntityFields.find((ef) => ef.inputType == "ENTITY");
    if (this.componentData?.loginFilter != "") {
      try {
        const response = await postAPICall("POST", "/entities-data/check", {
          entityId: this.getAuthenticatedUser.contact_type,
          entityDataId: this.getAuthenticatedUser.account_data_id,
          filterId: this.componentData.loginFilter,
        });
        if (response?.data) {
          this.apiSuccess = true;
        } else {
          this.apiSuccess = false;
        }
      } catch (error) {
        console.error("API Error:", error);
        this.apiSuccess = false;
      }
    }
  },
  methods: {
    getCurrentFieldOptions(template_id, key, entity) {
      let field;
      if (this.componentData.Days) {
        field = this.tableFields.find(
          (e) => e.key == key || e.key?.split("#")[1] == key
        );
      } else {
        field = entity.templates
          ?.find((e) => e?.templateInfo._id == template_id)
          .templateInfo.sections[0]?.fields?.find(
            (e) => e.key == key || e.key?.split("#")[1] == key
          );
      }
      let options = field?.options;
      if (field?.is_global_variable && field.global_variable_id) {
        let Field = (this.globalVariablesData || [])?.find(
          (e) => e._id == field.global_variable_id
        );
        return Field?.options || [];
      }
      return options || [];
    },
    async getCompanyInfo() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "company/fetchCompany",
          this.getActiveWorkspace.company_id
        );
        if (this.getCompanyDetails) {
          this.loading = false;
          this.brandingInfo = {
            ...this.getCompanyDetails,
          };
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    optionsDialogVisibleClose() {
      this.optionsDialogVisible = false;
    },
    expendTable() {
      this.$emit("expendTable", this.index);
    },
    expandTablewhenHide() {
      this.isExpandTable = false;
      this.$emit("expendTable", this.index);
    },
    deleteComponent() {
      this.$confirm("Are you sure to delete the Calendar?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$emit("deleteComponent", this.index);
      });
    },
    async getLoginEntityDateFields() {
      if (this.addStatsData?.entity_id) {
        this.allEntityFields = await this.fetchEntityDetails(
          this.addStatsData.entity_id,
          true,
          false,
          true
        );
        if (this.isEntityDashboard) {
          this.loginEntityFilters = await fetchAllEntityFiltersByEntity({
            entityId: this.$route.params.entityId,
          });
        }
        this.allDateTimeFields = this.allEntityFields.filter(
          (e) =>
            e.input_type == "TIME" ||
            e.input_type == "DATE" ||
            e.input_type == "SELECT"
        );
        this.allLocationFields =this.allEntityFields.filter(
          (e) =>
            e.input_type == "LOCATION"
        );
        this.entityField = this.allEntityFields.filter(
          (e) => e.input_type == "ENTITY"
        );
        this.textField = this.allEntityFields.filter(
          (e) => e.input_type == "SINGLE_LINE_TEXT"
        );
      }
    },
    editDialogBox() {
      this.addStatsData = JSON.parse(JSON.stringify(this.componentData));
      this.getLoginEntityDateFields();
      this.centerLoginDialogVisible = true;
    },
    saveEditData() {
      this.componentData = { ...this.addStatsData };
      this.$emit("tableUpdate", {
        index: this.index,
        data: this.componentData,
        component: "LOGINS",
      });
      this.centerLoginDialogVisible = false;
      this.$notify.info({
        title: "Info",
        message: "Please Click on Save to make Edit Changes",
      });
    },
    checklogin() {
      if (
        (this.componentData.loginsDataDetails.length > 4 &&
        this.loginLocation.field != "") || this.componentData?.isDropdownVisible == true
      ) {
        this.selectPopUp = true;
      } else {
        this.login();
      }
    },
    async login() {
      this.loading = true;
      this.data1 = [];
      let obj = {
        time1: moment().format("HH:mm:ss"),
        date: moment().format("YYYY-MM-DD"),
      };
      this.data2.push(obj);
      const contactTypeId = this.getActiveContactType?.contact_type?._id;
      const role_id = this.getAuthenticatedUser?.activeRole?.role_id;
      let details = {
        userEmail: this.getAuthenticatedUser.email,
        userId: this.getAuthenticatedUser._id,
      };
      if (contactTypeId) {
        details.workSpace = contactTypeId;
      } else if (role_id) {
        details.role = role_id;
      }

      let login = {};
      let toDate = {};
      let obj2 = {};
      let loginTemplatedId = "";
      let dateTemplateId = "";
      let tableObj = {};
      let tableArray = [];

      let dateField = this.componentData.loginsDataDetails.find(
        (fd) => fd.component == "Date"
      );
      let logInField = this.componentData.loginsDataDetails.find(
        (fd) => fd.component == "Login"
      );
      let logOutField = this.componentData.loginsDataDetails.find(
        (fd) => fd.component == "Logout"
      );
      let durationField = this.componentData.loginsDataDetails.find(
        (fd) => fd.component == "Duration"
      );
      this.tableKeyIndex = (Math.random() + 1).toString(36).substring(7);
      tableObj["keyIndex"] = this.tableKeyIndex;
      let template_id = logInField.field.split("#")[0];
      if (this.componentData.Days) {
        let tableData = this.tableFields.filter((e) => e.data_table_key);
        tableObj[logInField.field.split("#")[1]] = obj.time1;
        tableObj[dateField.field.split("#")[1]] = obj.date;
        tableObj[logOutField.field.split("#")[1] + "/autoLogout"] = moment(
          obj.time1,
          "HH:mm:ss"
        )
          .add(this.componentData.auto_logout, "hours")
          .format("HH:mm:ss");
        if (this.DropDownOption) {
          tableObj[this.loginLocationFieldParts.split("#")[1]] =
            this.DropDownOption;
        }
        let outTime = moment(obj.time1, "HH:mm:ss")
          .add(this.componentData.auto_logout, "hours")
          .format("HH:mm:ss");
        const momentObj1 = moment(obj.time1, "HH:mm:ss");
        const momentObj2 = moment(outTime, "HH:mm:ss");
        let duration = moment.utc(momentObj2.diff(momentObj1));
        tableObj[durationField.field.split("#")[1] + "/autoDuration"] =
          duration.format("HH:mm:ss");
        tableArray.push(tableObj);
        obj2[tableData[0].data_table_key] = tableArray;
      } else {
        this.componentData.loginsDataDetails.map((x) => {
          let template_id = x.field.split("#")[0];
          obj2[x.field.split("#")[1]] = "";
          if (x.component == "Login") {
            obj2[x.field.split("#")[1]] = obj.time1;
            login[x.field.split("#")[1]] = obj.time1;
            loginTemplatedId = x.field.split("#")[0];
          } else if (x.component == "Date") {
            obj2[x.field.split("#")[1]] = obj.date;
            toDate[x.field.split("#")[1]] = obj.date;
            dateTemplateId = x.field.split("#")[0];
          }
          if (this.componentData.auto_logout) {
            let outTime = "";
            if (x.component == "Logout") {
              obj2[x.field.split("#")[1] + "/autoLogout"] = moment(
                obj.time1,
                "HH:mm:ss"
              )
                .add(this.componentData.auto_logout, "hours")
                .format("HH:mm:ss");
              toDate[x.field.split("#")[1] + "/autoLogout"] = moment(
                obj.time1,
                "HH:mm:ss"
              )
                .add(this.componentData.auto_logout, "hours")
                .format("HH:mm:ss");
              dateTemplateId = x.field.split("#")[0];
            }
            if (x.component == "Duration") {
              outTime = moment(obj.time1, "HH:mm:ss")
                .add(this.componentData.auto_logout, "hours")
                .format("HH:mm:ss");
              const momentObj1 = moment(obj.time1, "HH:mm:ss");
              const momentObj2 = moment(outTime, "HH:mm:ss");
              let duration = moment.utc(momentObj2.diff(momentObj1));
              obj2[x.field.split("#")[1] + "/autoDuration"] =
                duration.format("HH:mm:ss");
            }
          }
          return template_id;
        });
        let postData = Object.keys(obj2);
        postData.map((x) => {
          if (obj2[x] == "") {
            delete obj2[x];
          }
        });
      }
      if (this.componentData.user_field) {
        obj2[this.userEntityField] = this.selectedApplicationUser;
        obj2[this.userEntityField + "/name"] =
          this.getAuthenticatedUser.first_name +
          " " +
          this.getAuthenticatedUser.last_name;
      }
      if (this.loginLocation) {
        obj2[this.loginLocation?.field?.split("#")[1]] = this.DropDownOption;
        this.selectPopUp = false;
        this.DropDownOption="";
      }
      if (this.componentData.companyuser_field) {
        obj2[this.componentData.companyuser_field.split("#")[1]] =
          this.getAuthenticatedUser.first_name +
          " " +
          this.getAuthenticatedUser.last_name;
      }
      let dateParams = {
        entity_id: this.componentData.entity_id,
        userId: this.getAuthenticatedUser._id,
        date: new Date(),
      };
      if (contactTypeId) {
        dateParams.workSpace = contactTypeId;
      } else if (role_id) {
        dateParams.role = role_id;
      }
      await this.$store.dispatch(
        "entities/fetchEntitiesDataByQuery",
        dateParams
      );
      if (this.componentData.Days) {
        let entityDataByQuery = this.getEntitiesDatasByQuery;
        let latestRecord = entityDataByQuery[entityDataByQuery.length - 1];
        let templateData = await this.getTemplateData(template_id);
        let fields = this.getTemplateFields(templateData);
        let form = this.mapDefaultValues(fields, obj2, form, false);
        form = this.applyFormRules(obj2, fields, templateData.rules);
        if (latestRecord == null) {
          let recordDate = moment();
          let endDate = recordDate
            .add(this.componentData.Days, "days")
            .format("YYYY-MM-DD");
          if (obj.date < endDate) {
            let payload = {
              template_data: form,
              entity_id: this.componentData.entity_id,
              template_id: template_id,
              template_completion_status: true,
              loginDetails: details,
              execute_default_values: true,
            };

            await this.$store.dispatch(
              "templatesData/createEntityDataByTemplateData",
              payload
            );
          }
        } else {
          let recordDate = moment();
          let endDate = recordDate
            .add(this.componentData.Days, "days")
            .format("YYYY-MM-DD");
          if (obj.date < endDate) {
            let dataOfPayload = {};
            if (this.componentData.user_field) {
              dataOfPayload = {
                [Object.keys(obj2)[0]]: [
                  ...latestRecord.entityData[template_id][Object.keys(obj2)[0]],
                  ...tableArray,
                ],
                [this.userEntityField]: this.selectedApplicationUser,
                [this.userEntityField + "/name"]:
                  this.getAuthenticatedUser.first_name +
                  " " +
                  this.getAuthenticatedUser.last_name,
              };
            } else {
              dataOfPayload = {
                [Object.keys(obj2)[0]]: [
                  ...latestRecord.entityData[template_id][Object.keys(obj2)[0]],
                  ...tableArray,
                ],
              };
            }
            if (this.componentData.companyuser_field) {
              dataOfPayload = {
                [Object.keys(obj2)[0]]: [
                  ...latestRecord.entityData[template_id][Object.keys(obj2)[0]],
                  ...tableArray,
                ],
                [this.componentData.companyuser_field.split("#")[1]]:
                  this.getAuthenticatedUser.first_name +
                  " " +
                  this.getAuthenticatedUser.last_name,
              };
            } else {
              dataOfPayload = {
                [Object.keys(obj2)[0]]: [
                  ...latestRecord.entityData[template_id][Object.keys(obj2)[0]],
                  ...tableArray,
                ],
              };
            }
            let recordData = latestRecord.entityData[template_id];
            let updatedRecord = { ...recordData, ...dataOfPayload };
            let templateData = await this.getTemplateData(template_id);
            let fields = this.getTemplateFields(templateData);
            let form = this.mapDefaultValues(
              fields,
              updatedRecord,
              form,
              false
            );
            let update = {
              template_data: form,

              // {
              //   [Object.keys(obj2)[0]] : [...latestRecord.entityData[template_id][Object.keys(obj2)[0]],...tableArray]
              // },
              entity_data_id: latestRecord._id,
              entity_id: this.componentData.entity_id,
              template_id: template_id,
              template_completion_status: true,
            };
            this.loading = true;
            await this.$store.dispatch(
              "templatesData/updateUserTemplateData",
              update
            );
          } else {
            let templateData = await this.getTemplateData(template_id);
            let fields = this.getTemplateFields(templateData);
            let form = this.mapDefaultValues(fields, obj2, form, false);
            let payload = {
              template_data: form,
              entity_id: this.componentData.entity_id,
              template_id: template_id,
              template_completion_status: true,
              loginDetails: details,
              execute_default_values: true,
            };
            this.loading = true;
            await this.$store.dispatch(
              "templatesData/createEntityDataByTemplateData",
              payload
            );
          }
        }
      } else if (loginTemplatedId == dateTemplateId) {
        let templateData = await this.getTemplateData(loginTemplatedId);
        let fields = this.getTemplateFields(templateData);
        let form = this.applyFormRules(obj2, fields, templateData.rules);
        let payload = {
          template_data: form,
          entity_id: this.componentData.entity_id,
          template_id: loginTemplatedId,
          template_completion_status: true,
          loginDetails: details,
          execute_default_values: true,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/createEntityDataByTemplateData",
          payload
        );
      } else {
        let templateData = await this.getTemplateData(loginTemplatedId);
        let fields = this.getTemplateFields(templateData);
        let form = this.applyFormRules(login, fields, templateData.rules);
        let payload = {
          template_data: form,
          entity_id: this.componentData.entity_id,
          template_id: loginTemplatedId,
          template_completion_status: true,
          entity_data_id: null,
          loginDetails: details,
          execute_default_values: true,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/createEntityDataByTemplateData",
          payload
        );
        let update = {
          template_data: toDate,
          entity_data_id:
            this.getEntitiesDatasByQuery[
              this.getEntitiesDatasByQuery.length - 1
            ]._id,
          entity_id: this.componentData.entity_id,
          template_id: dateTemplateId,
          template_completion_status: true,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          update
        );
      }
      this.entity_data_id = this.getNewEntityData._id;
      this.$notify({
        title: "Success",
        message: this.componentData.start_button,
        type: "success",
      });
      if (this.componentData.selectedEntityRelationalData) {
        let data = {
          parent_entity_id: this.getActiveContactType?.contact_type?._id,
          child_entity_id: this.componentData.entity_id,
          parent_entity_data_id: this.getActiveContactType?.account_data_id,
          child_entity_data_ids: [this.getNewEntityData._id],
        };

        await this.$store.dispatch(
          "entityRelationships/assignEntityRelationshipData",
          data
        );
      }

      this.contactTypeId = this.getActiveContactType?.contact_type?._id;
      this.role_id = this.getAuthenticatedUser?.activeRole?.role_id;
      let params = {
        entity_id: this.componentData.entity_id,
        userId: this.getAuthenticatedUser._id,
        date: new Date(),
      };
      if (contactTypeId) {
        params.workSpace = this.contactTypeId;
      } else if (role_id) {
        params.role = this.role_id;
      }
      await this.$store.dispatch("entities/fetchEntitiesDataByQuery", params);
      this.loading = false;
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    async getTemplateData(templateId) {
      await this.$store.dispatch(
        "companyTemplates/fetchSingleCompanyTemplate",
        templateId
      );
      return this.getSingleCompanyTemplate;
    },
    checklogout() {
      if (
        (this.componentData?.loginsDataDetails.length > 4 &&
        this.logoutLocation.field != "") || this.componentData?.isDropdownVisible == true
      ) {
        this.selectlogoutPopUp = true;
      } else {
        this.logout();
      }
    },
    async logout() {
      this.data2["time2"] = "";
      this.data2["duration"] = "";
      this.data2[this.data2.length - 1].time2 = moment().format("HH:mm:ss");
      let inTime = moment(this.data2[this.data2.length - 1].time1, "HH:mm:ss");
      let outTime = moment(this.data2[this.data2.length - 1].time2, "HH:mm:ss");
      this.data2[this.data2.length - 1].duration = moment
        .utc(outTime.diff(inTime))
        .format("HH:mm:ss");
      this.form = [...this.data2];
      let logout = {};
      let duration = {};
      // let login = {};
      // let toDate = {};
      let obj2 = {};
      let logoutTemplateId = "";
      let durationTemplateId = "";
      // let loginTemplateId = "";
      // let dateTemplateId = "";
      let tableObj = {};
      let tableArray = [];
      let logInField = this.componentData.loginsDataDetails.find(
        (fd) => fd.component == "Login"
      );
      let logOutField = this.componentData.loginsDataDetails.find(
        (fd) => fd.component == "Logout"
      );
      let durationField = this.componentData.loginsDataDetails.find(
        (fd) => fd.component == "Duration"
      );
      let template_id = logInField.field.split("#")[0];
      if (this.componentData.Days) {
        let tableData = this.tableFields.filter((e) => e.data_table_key);
        tableObj[logOutField.field.split("#")[1]] =
          this.data2[this.data2.length - 1].time2;
        tableObj[durationField.field.split("#")[1]] =
          this.data2[this.data2.length - 1].duration;
        if (this.DropDownOption2) {
          tableObj[this.logoutLocationFieldParts.split("#")[1]] =
            this.DropDownOption2;
        }
        tableArray.push(tableObj);
        obj2[tableData[0].data_table_key] = tableArray;
      } else {
        obj2[logOutField.field.split("#")[1]] =
          this.data2[this.data2.length - 1].time2;
        obj2[durationField.field.split("#")[1]] =
          this.data2[this.data2.length - 1].duration;
        logoutTemplateId = logOutField.field.split("#")[0];
        durationTemplateId = durationField.field.split("#")[0];
      }
      if (this.logoutLocation) {
        obj2[this.logoutLocation?.field?.split("#")[1]] = this.DropDownOption2;
        this.selectlogoutPopUp = false;
        this.DropDownOption2="";
      }

      if (this.componentData.user_field) {
        obj2[this.userEntityField] = this.selectedApplicationUser;
        obj2[this.userEntityField + "/name"] =
          this.getAuthenticatedUser.first_name +
          " " +
          this.getAuthenticatedUser.last_name;
      }
      if (this.componentData.companyuser_field) {
        obj2[this.componentData.companyuser_field.split("#")[1]] =
          this.getAuthenticatedUser.first_name +
          " " +
          this.getAuthenticatedUser.last_name;
      }
      if (this.componentData.Days) {
        let entityDataByQuery = this.getEntitiesDatasByQuery;
        let latestRecord = entityDataByQuery[entityDataByQuery.length - 1];
        let preData =
          latestRecord.entityData[template_id][Object.keys(obj2)[0]];
        const newArray = [
          ...preData.slice(0, -1),
          { ...preData[preData.length - 1], ...tableObj },
        ];
        let dataOfPayload = {};
        if (this.componentData.user_field) {
          dataOfPayload = {
            [Object.keys(obj2)[0]]: [...newArray],
            [this.userEntityField]: this.selectedApplicationUser,
            [this.userEntityField + "/name"]:
              this.getAuthenticatedUser.first_name +
              " " +
              this.getAuthenticatedUser.last_name,
          };
        } else {
          dataOfPayload = { [Object.keys(obj2)[0]]: [...newArray] };
        }
        if (this.componentData.companyuser_field) {
          dataOfPayload = {
            [Object.keys(obj2)[0]]: [...newArray],
            [this.componentData.companyuser_field.split("#")[1]]:
              this.getAuthenticatedUser.first_name +
              " " +
              this.getAuthenticatedUser.last_name,
          };
        } else {
          dataOfPayload = { [Object.keys(obj2)[0]]: [...newArray] };
        }
        let recordData = latestRecord.entityData[template_id];
        let updatedRecord = { ...recordData, ...dataOfPayload };
        let templateData = await this.getTemplateData(template_id);
        let fields = this.getTemplateFields(templateData);
        let form = this.mapDefaultValues(fields, updatedRecord, form, false);
        let payload = {
          template_data: form,
          entity_data_id:
            this.getEntitiesDatasByQuery[
              this.getEntitiesDatasByQuery.length - 1
            ]._id,
          entity_id: this.componentData.entity_id,
          template_id: template_id,
          template_completion_status: true,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          payload
        );
      } else if (durationTemplateId == logoutTemplateId) {
        let entityDataByQuery = this.getEntitiesDatasByQuery;
        let latestRecord = entityDataByQuery[entityDataByQuery.length - 1];
        let recordData = latestRecord.entityData[template_id];
        let templateData = await this.getTemplateData(durationTemplateId);
        let updatedRecord = { ...recordData, ...obj2 };
        let fields = this.getTemplateFields(templateData);
        let form = this.applyFormRules(
          updatedRecord,
          fields,
          templateData.rules
        );
        let payload = {
          template_data: form,
          entity_data_id:
            this.getEntitiesDatasByQuery[
              this.getEntitiesDatasByQuery.length - 1
            ]._id,
          entity_id: this.componentData.entity_id,
          template_id: logoutTemplateId,
          template_completion_status: true,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          payload
        );
      } else {
        let payload = {
          template_data: logout,
          entity_data_id:
            this.getEntitiesDatasByQuery[
              this.getEntitiesDatasByQuery.length - 1
            ]._id,
          entity_id: this.componentData.entity_id,
          template_id: logoutTemplateId,
          template_completion_status: true,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          payload
        );
        let update = {
          template_data: duration,
          entity_data_id:
            this.getEntitiesDatasByQuery[
              this.getEntitiesDatasByQuery.length - 1
            ]._id,
          entity_id: this.componentData.entity_id,
          template_id: durationTemplateId,
          template_completion_status: true,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          update
        );
      }
      this.$notify({
        title: "Success",
        message: this.componentData.end_button,
        type: "success",
      });
      this.contactTypeId = this.getActiveContactType?.contact_type?._id;
      this.role_id = this.getAuthenticatedUser?.activeRole?.role_id;
      let params = {
        entity_id: this.componentData.entity_id,
        userId: this.getAuthenticatedUser._id,
        date: new Date(),
      };
      if (this.contactTypeId) {
        params.workSpace = this.contactTypeId;
      } else if (this.role_id) {
        params.role = this.role_id;
      }
      await this.$store.dispatch("entities/fetchEntitiesDataByQuery", params);
      this.loading = false;
    },
    toggleLoginState() {
      this.isLoggedIn = !this.isLoggedIn;
      this.recordedTimes.push(this.getCurrentTime());
      this.getDifference();
    },
    getDateTime() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      this.todayDate = `${day}/${month}/${year}`;
    },
    updateTime() {
      const now = new Date();
      this.hours = now.getHours().toString().padStart(2, "0");
      this.minutes = now.getMinutes().toString().padStart(2, "0");
      this.seconds = now.getSeconds().toString().padStart(2, "0");
    },
    getCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const seconds = now.getSeconds().toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    },
    getDayOfWeek(date) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayIndex = date.getDay();
      return daysOfWeek[dayIndex];
    },
    updateClock() {
      this.currentTime = this.getCurrentTime();
    },
    async openSwipes() {
      const contactTypeId = this.getActiveContactType?.contact_type?._id;
      const role_id = this.getAuthenticatedUser?.activeRole?.role_id;
      let params = {
        entity_id: this.componentData.entity_id,
        userId: this.getAuthenticatedUser._id,
        date: new Date(),
      };
      if (contactTypeId) {
        params.workSpace = this.contactTypeId;
      } else if (role_id) {
        params.role = this.role_id;
      }
      await this.$store.dispatch("entities/fetchEntitiesDataByQuery", params);
      this.data1 = [];
      if (this.getEntitiesDatasByQuery?.length) {
        let logInField = this.componentData.loginsDataDetails.find(
          (fd) => fd.component == "Login"
        );
        let logOutField = this.componentData.loginsDataDetails.find(
          (fd) => fd.component == "Logout"
        );
        let durationField = this.componentData.loginsDataDetails.find(
          (fd) => fd.component == "Duration"
        );
        if (this.componentData.Days) {
          let tableData = this.tableFields.filter((e) => e.data_table_key);
          let tableName = tableData[0].data_table_key;
          this.getEntitiesDatasByQuery.map((eData) => {
            let rowData =
              eData.entityData[logInField.field.split("#")[0]][tableName];
            rowData.map((row) => {
              this.data1.push({
                time1: row[logInField.field.split("#")[1]],
                time2: row[logOutField.field.split("#")[1]],
                duration: row[durationField.field.split("#")[1]],
              });
            });
          });
        } else {
          this.getEntitiesDatasByQuery.map((eData) => {
            this.data1.push({
              time1:
                eData.entityData[logInField.field.split("#")[0]][
                  logInField.field.split("#")[1]
                ],
              time2:
                eData.entityData[logOutField.field.split("#")[0]][
                  logOutField.field.split("#")[1]
                ],
              duration:
                eData.entityData[durationField.field.split("#")[0]][
                  durationField.field.split("#")[1]
                ],
            });
          });
        }
      }
      this.optionsDialogVisible = true;
    },
    isFieldDisabled(field) {
      let fieldSelected = false;
      let selectedFirstField;
      this.addStatsData.loginsDataDetails.map((e) => {
        if (e.field) {
          fieldSelected = true;
          selectedFirstField = e;
        }
      });
      if (!fieldSelected) {
        this.selectedFieldType = "";
        this.dateTimeFieldObject = null;
        return false;
      }
      if (!this.dateTimeFieldObject) {
        let key = selectedFirstField.field;
        this.dateTimeFieldObject = this.allDateTimeFields.find(
          (e) => e.template_key == key
        );
        if (
          this.dateTimeFieldObject &&
          this.dateTimeFieldObject.isDataTableField
        ) {
          this.selectedFieldType = "data_table";
          this.shouldDisplayInput = true;
        } else {
          this.selectedFieldType = "normal";
          this.shouldDisplayInput = false;
        }
      }
      if (!this.dateTimeFieldObject) {
        return false;
      }
      if (
        this.selectedFieldType == "data_table" &&
        field.isDataTableField &&
        this.addStatsData.loginsDataDetails.findIndex(
          (ex) => ex.field == field.template_key
        ) == -1
      ) {
        return false;
      } else if (
        this.selectedFieldType == "normal" &&
        !field.isDataTableField &&
        this.addStatsData.loginsDataDetails.findIndex(
          (ex) => ex.field == field.template_key
        ) == -1
      ) {
        return false;
      } else {
        return true;
      }
    },
    async getEpicenter(address) {
      try {
        const config = {
          params: {
            address: address,
            sensor: true,
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
          },
        };

        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          config
        );

        const coordinates = response.data.results[0].geometry.location;
        this.geoFenceCoordinates = [
          coordinates.lat,
          coordinates.lng,
        ];
        this.$set(
          this.geoFenceCoordinates,
          `${this.coordinates[0]}, ${this.coordinates[1]}`
        );
      } catch (error) {
        console.error("getCoordinates", error);
        return null;
      }
    },
    isInsideGeoFence() {
  if (!this.userCoordinates || !this.componentData.geoFenceCoordinates || !this.componentData.geoFenceRadius) {
    return false;
  }
 
  const [lat1, lon1] = this.userCoordinates; 
  const [lat2, lon2] = this.componentData.geoFenceCoordinates; 
  const radius = this.componentData.geoFenceRadius; 
 
  const toRadians = (degree) => (degree * Math.PI) / 180;
 
  const R = 6371000; 
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; 
  return distance <= radius;
  },
  getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.userCoordinates = [
              position.coords.latitude,
              position.coords.longitude,
            ];
            this.center = this.userCoordinates;
           
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style lang="scss">
// .avatar-uploader {
//   display: flex;
//   align-items: center;
//   height: 2em;
//   border-radius: 8px;
//   width: 13em;
//   border-width: 2px;
//   border-style: initial;
//   box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
// }

// .center-row {
//   display: flex;
//   align-items: center;
//   height: 15vh;
//   margin: auto;
// }
.sign_img {
  height: 20px;
  width: 22px;
}
.color {
  color: var(--primary-contrast-color);
  justify-content: right !important;
  font-size: 15px;
  top: 62px;
  position: absolute;
  margin-left: 20px;
}
.button {
  border-radius: 5px;
  justify-content: right !important;
  height: 2em;
  font-size: medium;
  font-variant-position: sub;
  display: flex;
  align-items: center;
}
.timeStyles {
  font-size: 15px;
  color: var(--primary-contrast-color);
  font-style: normal;
  padding-left: 20px;
}
.DateStyles {
  font-size: 15px;
  color: var(--primary-contrast-color);
  font-style: normal;
  justify-content: left !important;
  padding-left: 20px;
  margin-bottom: 5px;
}
.DayStyles {
  font-size: 15px;
  color: var(--primary-contrast-color);
  font-style: normal;
  justify-content: left !important;
  padding-left: 20px;
  margin-top: 0;
}

.avatar-uploader {
  height: 50px;
  justify-content: left !important;
  text-align: none;
  border-radius: 8px;
  width: 30%;
  border-width: 2px;
  border-style: initial;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.section-wrapper {
  position: relative;
}

.bottom-right-content {
  position: absolute;
  top: 55px;
  right: 18px;
}
.content-container {
  margin: 10px;
}
.button-text {
  margin-left: 2px;
  font-size: 14px;
  align-items: center;
}
.text-wrpper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>

<style scoped>
.login-component {
  background-color: var(--lighter-primary-color);
  color: var(--primary-contrast-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  margin: 0em 1em;
  height: 15rem;
  width: auto;
}

.title {
  border-radius: 20px;
}

.image-size {
  height: 1em;
  width: 1em;
}

.weather-picture {
  padding-left: 65px;
  padding-right: 0px;
}

.label {
  color: slategray;
}

@media (max-width: 600px) {
  .login-component {
    background-color: var(--lighter-primary-color);
    color: var(--primary-contrast-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    margin: 0em 1em;
    height: 15rem;
    width: auto;
  }

  .title {
    border-radius: 20px;
  }
  .image-size {
    height: 1em;
    width: 1em;
  }

  .weather-picture {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
